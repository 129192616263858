<template>
  <div>
    <div class="content adjunction">
      <span>编辑/添加故障原因</span>
      <el-divider></el-divider>
      <el-row>
        <el-col :span="10">
          <el-form
            label-position="top"
            label-width="80px"
            :model="form"
            hide-required-asterisk
            ref="form"
            :rules="rules"
          >
            <el-form-item prop="breakdownCode" label="故障原因编码">
              <el-input
                type="text"
                v-model="form.breakdownCode"
                maxlength="50"
              ></el-input>
            </el-form-item>
            <el-form-item prop="breakdownType" label="故障原因类型">
              <el-select v-model="form.breakdownType" placeholder="请选择">
                <el-option label="控制器故障" value="1"> </el-option>
                <el-option label="巡检故障" value="2"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="breakdownTitle" label="故障原因标题">
              <el-input
                type="text"
                v-model="form.breakdownTitle"
                maxlength="50"
              ></el-input>
            </el-form-item>
            <el-form-item prop="remark" label="描述信息">
              <el-input
                type="textarea"
                v-model="form.remark"
                maxlength="250"
              ></el-input>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>
    <el-button class="button_cancel input" @click="cancel">取消</el-button>
    <el-button class="button_preserve" @click="submit" :loading="loading.save"
      >保存</el-button
    >
  </div>
</template>

<script>
import { save_breakdown_reason_info } from "../../../api/abnormalAdmin/glitchReson";
export default {
  name: "imgAdd",
  data() {
    return {
      id: "",
      form: {
        breakdownCode: "",
        breakdownTitle: "",
        breakdownType: "1",
        remark: ""
      },
      rules: {
        breakdownType: [
          {
            required: true,
            message: "请选择故障类型",
            trigger: "change"
          }
        ],
        breakdownCode: [
          {
            required: true,
            message: "请输入故障原因编码",
            trigger: "change"
          }
        ],
        breakdownTitle: [
          {
            required: true,
            message: "请输入故障原因标题",
            trigger: "change"
          }
        ]
      },
      loading: {
        save: false
      }
    };
  },
  created() {
    const editDatas = this.$route.query || {};
    this.form = {
      ...this.form,
      ...editDatas
    };
  },
  methods: {
    //取消
    cancel() {
      this.$router.back();
    },
    submit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.loading.save = true;
          save_breakdown_reason_info(this.form).then(res => {
            this.loading.save = false;
            if (res.data.desc == "SUCCESS") {
              this.$emit("confirm");
              this.$message({
                showClose: true,
                message: "保存成功！",
                type: "success"
              });
              this.$router.back();
            } else {
              this.$message({
                showClose: true,
                message: res.data.errorDesc || "保存失败！",
                type: "error"
              });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }
  }
};
</script>

<style scoped>
.input {
  margin-top: 20px;
}
.adjunction {
  background: #ffffff;
  border: 1px solid #e8eaf0;
  border-radius: 3px;
  border-radius: 3px;
}
/* 多选框样式 */
/deep/ .el-checkbox {
  display: block;
  width: 30px;
  height: 25px;
}
/deep/ .Code_item .el-checkbox:last-of-type {
  margin-left: 30px;
}
/deep/ el-form-item__label {
  color: #333;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #333333;
  letter-spacing: -0.04px;
  line-height: 16px;
}
/deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #606266;
}

.content {
  width: 97%;
  /* height: 700px; */
  background: #fff;
  padding: 30px 30px;
}

/deep/ .el-divider--horizontal {
  margin: 15px 0 !important;
}
</style>
